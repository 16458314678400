<template>
  <modal
    transition="nice-modal-fade"
    width="680"
    :adaptive="true"
    :reset="true"
    height="auto"
    :name="modalName"
    @before-open="beforeOpen"
  >
    <div class="modal--wrapper">
      <div class="back--wrapper" v-if="$mq === 'xs'">
        <div class="back--button" @click="close()">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        {{ $t('booking.modal.rejectBook.title') }}
      </div>
      <SectionLoader :show="loading" />
      <section class="modal--container accept--survey-container">
        <div class="title--text" v-if="$mq !== 'xs'">
          {{ $t('booking.modal.rejectBook.title') }}
        </div>
        <div class="date--time">
          {{ $t('booking.modal.rejectBook.message') }}
        </div>
        <hr class="separator" />
        <div class="d-flex justify-content-between" :class="{ 'flex-wrap': $mq === 'xs' }">
          <div class="btn btn-primary--outline main--btn" @click="close">
            {{ $t('general.btn.back') }}
          </div>
          <div class="btn btn-primary main--btn" @click="reject">
            {{ buttonName }}
          </div>
          <!--          <div class="btn btn-primary main&#45;&#45;btn" @click="close">-->
          <!--            Terima Permintaan Survei-->
          <!--          </div>-->
        </div>
      </section>
      <button type="button" class="close" @click="close" v-if="$mq !== 'xs'">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
    </div>
  </modal>
</template>

<script>
const SectionLoader = () => import('@/components/content-loading/section-loading');

export default {
  name: 'rent-reject-modal',
  components: { SectionLoader },
  data() {
    return {
      modalName: 'rent-reject-modal',
      loading: false,
      buttonName: '',
      uuid: null,
      actionName: null,
    };
  },
  methods: {
    close() {
      this.$modal.hide(this.modalName);
    },
    beforeOpen(event) {
      this.uuid = event.params.uuid;
      this.buttonName = event.params.buttonName;
      this.actionName = event.params.actionName;
    },
    async reject() {
      this.loading = true;
      try {
        if (this.actionName === 'CANCEL_PAYMENT') {
          await this.$store.dispatch('v2/rentTransaction/cancelBeforePayment', this.uuid);
        } else if (this.actionName === 'CANCEL_APPLICANT_SIGN') {
          await this.$store.dispatch('v2/rentTransaction/cancelApplicantSign', this.uuid);
        } else if (this.actionName === 'CANCEL_OWNER_ACCEPT') {
          await this.$store.dispatch('v2/rentTransaction/cancelOwnerConfirmBooking', this.uuid);
        }
        this.$emit('actionDone');
        this.close();
        this.$swal(
          this.$t('general.success'),
          this.$t('booking.swal.rejectBookModal.message'),
          'success',
        );
        // eslint-disable-next-line no-empty
      } catch (_) {
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped></style>
